import Layout from '../../components/layouts/freshest/FreshestLayout';
import AppStorePage from '../../components/appstore/AppStorePage';

const AppStoreTemplate = ( ) => {

    return (
        <Layout pageTitle='AppStore' className='appstoreLayout' pageDescription={'Apps that will allow you to interact with MetaDefender Cloud '}>
            <AppStorePage/>
        </Layout>
    );
};


export default AppStoreTemplate;
